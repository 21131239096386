<template>
  <template v-if="isLoading" />
  <template v-else-if="isTokenValid">
    <div class="login__form-title">
      Установите новый пароль
    </div>
    <change-password-form :action="changePassword" :token="token" />
  </template>
  <template v-else>
    <div class="login__form-title">
      Восстановление пароля
    </div>
    <password-recovery-form />
  </template>

  <div class="login__copy">
    <router-link class="login__copy-text" to="/policy">
      Политика конфиденциальности
    </router-link>
    <div class="login__signature">
      ©{{ currentYear }}, «DSF Trading Co»
    </div>
  </div>
</template>

<script>
import ChangePasswordForm from '@/components/auth/ChangePasswordForm.vue'
import PasswordRecoveryForm from '@/components/auth/PasswordRecoveryForm.vue'
import { useApi } from '@/api'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  components: {
    ChangePasswordForm,
    PasswordRecoveryForm
  },
  props: {
    token: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const isLoading = ref(!!props.token)
    const isTokenValid = ref(false)
    const api = useApi()
    const currentYear = ref((new Date()).getFullYear())
    if (props.token) {
      const router = useRouter()
      const toast = useToast()
      api.auth.checkRecoveryToken(props.token).then(() => {
        isTokenValid.value = true
      }).catch(() => {
        isTokenValid.value = false
        toast.error('Недействительная ссылка')
        router.push('/password-recovery')
      }).finally(() => {
        isLoading.value = false
      })
    }
    const changePassword = api.auth.changePassword.bind(api.auth)
    return {
      changePassword,
      isTokenValid,
      isLoading,
      currentYear
    }
  }
}
</script>
