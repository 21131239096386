<template>
  <vue-final-modal v-model="showModal" @closed="showModal = false">
    <div class="modal-form__box">
      <button class="modal-form__close js-close" type="button" @click="closeModal">
        <svg class="s-icon s-icon--largest">
          <use xlink:href="/img/svg/sprite1.svg#cancel" />
        </svg>
        <span class="visually-hidden">Закрыть модальное окно</span>
      </button>
      <div class="modal-form__title">
        Восстановление пароля
      </div>
      <p>Информация для восстановления доступа к личному кабинету была отправлена на Ваш email.</p>
      <button class="btn btn--primary btn--large" @click.prevent="showModal = false">
        ОК
      </button>
    </div>
  </vue-final-modal>
</template>

<script>
import { computed } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

export default {
  components: {
    VueFinalModal
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showModal = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
      }
    })
    return {
      showModal
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-form__box {
  font-size: 16px;
}
</style>
