<template>
  <form class="login__form form" @submit.prevent>
    <label class="field field--dark login__field" :class="{ 'v-error': form.errors.new_password }">
      <span class="visually-hidden">Новый пароль</span>
      <input
        v-model="form.body.new_password"
        class="field__input"
        type="password"
        :disabled="isSubmitting"
      >
      <span v-if="form.errors.new_password" class="form__error active">{{ form.errors.new_password }}</span>
    </label>
    <label class="field field--dark login__field" :class="{ 'v-error': form.errors.confirmation_password }">
      <span class="visually-hidden">Новый пароль еще раз</span>
      <input
        v-model="form.body.confirmation_password"
        class="field__input"
        type="password"
        :disabled="isSubmitting"
      >
      <span v-if="form.errors.confirmation_password" class="form__error active">{{ form.errors.confirmation_password }}</span>
    </label>

    <div class="login__row">
      <button :disabled="isSubmitting" class="btn btn--primary-light btn--large login__submit" @click.prevent="changePassword">
        Сохранить
      </button>
      <router-link class="login__form-link" to="/login">
        Авторизация
      </router-link>
    </div>
  </form>
</template>

<script>
import { catchFormError } from '@/common/catchFormError'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

export default {
  props: {
    action: {
      type: Function,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const form = reactive({
      body: {
        new_password: '',
        confirmation_password: ''
      },
      errors: {}
    })
    const isSubmitting = ref(false)
    const router = useRouter()
    const toast = useToast()
    const changePassword = () => {
      isSubmitting.value = true
      props.action(props.token, form.body).then(() => {
        router.push('/')
      }).catch(catchFormError(form, toast)).finally(() => {
        isSubmitting.value = false
      })
    }
    return {
      isSubmitting,
      changePassword,
      form
    }
  }
}
</script>
