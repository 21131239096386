<template>
  <form class="login__form form" @submit.prevent>
    <label class="field field--dark login__field" :class="{ 'v-error': form.errors.email }">
      <span class="visually-hidden">Введите E-mail</span>
      <input
        v-model="form.body.email"
        class="field__input"
        type="email"
        placeholder="E-mail"
        :disabled="isSubmitting"
      >
      <span v-if="form.errors.email" class="form__error active">{{ form.errors.email }}</span>
    </label>

    <div class="login__row">
      <button :disabled="isSubmitting" class="btn btn--primary-light btn--large login__submit" @click.prevent="recoverPassword">
        Отправить
      </button>
      <router-link class="login__form-link" to="/login">
        Авторизация
      </router-link>
    </div>
  </form>
  <recovery-sent-modal v-model="showModal" />
</template>

<script>
import RecoverySentModal from './RecoverySentModal.vue'
import { catchFormError } from '@/common/catchFormError'
import { useApi } from '@/api'
import { reactive, ref } from 'vue'
import { useToast } from 'vue-toastification'

export default {
  components: {
    RecoverySentModal
  },
  setup() {
    const isSubmitting = ref(false)
    const showModal = ref(false)
    const form = reactive({
      body: {
        email: ''
      },
      errors: {}
    })
    const api = useApi()
    const toast = useToast()
    const recoverPassword = () => {
      isSubmitting.value = true
      api.auth.requestPasswordRecovery(form.body.email).then(() => {
        showModal.value = true
        form.body.email = ''
      }).catch(catchFormError(form, toast)).finally(() => {
        isSubmitting.value = false
      })
    }
    return {
      isSubmitting,
      recoverPassword,
      showModal,
      form
    }
  }
}
</script>
